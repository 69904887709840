import * as React from "react";
import Helmet from "react-helmet";
import { Header } from "./header";

import "./index.css";
import "./layout.css";

interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
  location: {
    pathname: string;
  };
  children: any;
}

class DefaultLayout extends React.PureComponent<DefaultLayoutProps, void> {
  public render() {
    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Helmet
          title={`Jade & Daniel's Wedding`}
          meta={[
            {
              name: "description",
              content: "The website for Jade and Daniel's Wedding",
            },
            {
              name: "keywords",
              content: "wedding, daniel, jade, padmore, love",
            },
          ]}
        />
        <Header />
        {this.props.children}
      </div>
    );
  }
}

export default DefaultLayout;
