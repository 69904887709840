// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accommodation-tsx": () => import("./../../../src/pages/accommodation.tsx" /* webpackChunkName: "component---src-pages-accommodation-tsx" */),
  "component---src-pages-additional-info-tsx": () => import("./../../../src/pages/additional-info.tsx" /* webpackChunkName: "component---src-pages-additional-info-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menu-options-tsx": () => import("./../../../src/pages/menu-options.tsx" /* webpackChunkName: "component---src-pages-menu-options-tsx" */),
  "component---src-pages-registry-tsx": () => import("./../../../src/pages/registry.tsx" /* webpackChunkName: "component---src-pages-registry-tsx" */),
  "component---src-pages-rsvp-tsx": () => import("./../../../src/pages/rsvp.tsx" /* webpackChunkName: "component---src-pages-rsvp-tsx" */)
}

