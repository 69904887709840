import * as React from "react";
import Link from "gatsby-link";

export const Header = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <div
      style={{
        background: "#D896AEB5",
      }}
    >
      <div
        style={{
          margin: "0 auto",
          paddingTop: 40,
          paddingBottom: 10,
        }}
      >
        <h1
          className="site-title"
          style={{
            margin: 0,
            textAlign: "center",
            color: "black",
            textDecoration: "none",
            fontFamily: "'Great Vibes', cursive",
            fontWeight: 200,
          }}
        >
          Jade and Daniel's Wedding
        </h1>
        <div className="nav-separator" />
        <nav className="nav-container">
          <div
            className={`menu-button ${menuOpen ? "open" : ""}`}
            onClick={() => {
              setMenuOpen((x) => !x);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Link
            className={`nav-link ${menuOpen ? "open" : ""}`}
            to="/"
            onClick={closeMenu}
          >
            Home
          </Link>
          <Link
            className={`nav-link ${menuOpen ? "open" : ""}`}
            to="/menu-options"
            onClick={closeMenu}
          >
            Menu Options
          </Link>
          <Link
            className={`nav-link ${menuOpen ? "open" : ""}`}
            to="/accommodation"
            onClick={closeMenu}
          >
            Accommodation
          </Link>
          <Link
            className={`nav-link ${menuOpen ? "open" : ""}`}
            to="/registry"
            onClick={closeMenu}
          >
            Registry
          </Link>
          <Link
            className={`nav-link ${menuOpen ? "open" : ""}`}
            to="/additional-info"
            onClick={closeMenu}
          >
            Additional Info
          </Link>
        </nav>
      </div>
    </div>
  );
};
